.foto-perfil-quadrada {
  width: 100%;
  max-width: 350px;
}

.text-greey {
    color: #979797;
}

.bg-greey-50{
    background: #F2F2F2;
}

span.icon-whats{
    background-image: url('../../../public/media/icon/Whatsapp.svg');
}

span.icon-email{
    background-image: url('../../../public/media/icon/Email.svg');
}

span.icon-linkedin{
    background-image: url('../../../public/media/icon/LinkedIn.svg');
}

.button-menu{
    transform: rotate(90deg);
    letter-spacing: 2px;
}

span.icon-redes-sociais {
    height: 20px;
    min-width: 23px;
    display: block;
    text-indent: -999999px;
    margin-right: 5px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

hr.header {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid white;
  margin: 1.5em 0;
  padding: 0;
  opacity: 0.8;
}

.section-header {
  transition: 1s;
}

.section-projects{
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.section-projects > .container > .row{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.btn-fale-comigo{
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    padding: 8px 20px
}

ul {
  font-weight: bold;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  padding-left: 0 !important;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 3rem 2rem;
  text-decoration: none;
}

.carousel-indicators [data-bs-target] {
    width: 6px !important;
    height: 6px !important;
    border-radius: 100% !important;
}

@media (min-width: 1200px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 950px !important;
  }
}

@media (min-width: 768px) {
    .foto-perfil-quadrada {
        margin-bottom: -4rem;
      }
  }

@media (max-width: 768px) {
    .container {
        padding: 0px 2rem !important;
    }
  }
